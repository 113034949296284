import React from 'react'
import {Zoom} from 'react-reveal';

const HomeText = () => {
  return (
    <div>
        <Zoom right>
        <div className='tester'>
   <div class="scrolling-words-container">
  <div class="scrolling-words-box">
    <ul>
      <li style={{color: "#ea4335"}}>Capture</li>
      <li style={{color: "#4285f4"}}>Convert</li>
      <li style={{color: "#34a853"}}>Conquer</li>
      <li style={{color: "#012060"}}>Capture</li>
      <li style={{color: "#fbbc04"}}>Convert</li>
    </ul>
  </div>
  <span style={{color: "#5f6368"}}>leads with Blaccdot</span>
</div>
    </div>
      <h1 style={{ fontWeight: '700', fontFamily: '"Epilogue", sans-serif', textAlign: 'center',color: '#04121f',letterSpacing: '-1.6px',marginTop: '0', marginBottom: '0',fontSize: '40px',ineHeight: '130%' }}>Lead management system</h1> 
  
      </Zoom>
       
    </div>
  )
}

export default HomeText
