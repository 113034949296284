import React from 'react';
import Section from '../Images/Section.png';
import HwwRegister from '../Images/HwwRegister.png'
import Hwwselectlead from '../Images/Hwwselectlead.jpg'
import PaymentIcon from '../Images/PaymentIcon.png';
import LeadMangement from '../Images/LeadMangement.png';

const Section2 = () => {
  return (
    <div>
      <div className='howitwork' style={{backgroundImage:`url(${Section})`, width:'100%',height:'785px'}}>
          <div>
      <header class="header-design">



  <div class="footer-wave"></div>
</header>
<div class="pset">
  
  <div class="container">
  <div class="listar-map-button">
    <div class="listar-map-button-text" style={{display: 'inline-block', opacity: '1'}}>
      <span class="icon-map2">
      The Ultimate Lead Management Solution </span>
    </div>
  </div>
    <div class="row listar-feature-items">
      <div class="col-xs-12 col-sm-6 col-md-3 listar-feature-item-wrapper listar-feature-with-image listar-height-changed" data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
        <div class="listar-feature-item listar-feature-has-link">
          <div class="listar-feature-item-inner">
            <div class="listar-feature-right-border"></div>
            <div class="listar-feature-block-content-wrapper">
              <div class="listar-feature-icon-wrapper">
                <div class="listar-feature-icon-inner">
                  <div>
                    <img alt="Businesses" class="listar-image-icon" src={HwwRegister}/>
                  </div>
                </div>
              </div>
              <div class="listar-feature-content-wrapper" style={{paddingTop: '0px'}}>
                <div class="listar-feature-item-title listar-feature-counter-added">
                  <span className='howitworkpoint'><span style={{paddingTop: '20px'}}>01</span>
                  Register Your Lead Account </span>
                </div>
                <div class="listar-feature-item-excerpt">
                Get started by setting up your Blaccdot lead management account easily online. </div>
              </div>
            </div>
          </div>
        </div>
        <div class="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3 listar-feature-item-wrapper listar-feature-with-image listar-height-changed" data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
        <div class="listar-feature-item listar-feature-has-link">
          <div class="listar-feature-item-inner">
            <div class="listar-feature-right-border"></div>
            <div class="listar-feature-block-content-wrapper">
              <div class="listar-feature-icon-wrapper">
                <div class="listar-feature-icon-inner">
                  <div>
                    <img alt="Customers" style={{ marginBottom: '20px'}} class="listar-image-icon" src={Hwwselectlead}/>
                  </div>
                </div>
              </div>
              <div class="listar-feature-content-wrapper" style={{paddingTop: '0px'}}>
                <div class="listar-feature-item-title listar-feature-counter-added">
                  <span className='howitworkpoint'><span style={{paddingTop: '20px'}}>02</span>
                  Choose Lead Packages</span>
                </div>
                <div class="listar-feature-item-excerpt">
                Select from flexible lead packages tailored to your business needs and budget.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3 listar-feature-item-wrapper listar-feature-with-image listar-height-changed" data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
        <div class="listar-feature-item listar-feature-has-link">

          {/* <a href="https://codepen.io/uiswarup/full/yLzypyY" target="_blank"></a> */}

          <div class="listar-feature-item-inner">

            <div class="listar-feature-right-border"></div>

            <div class="listar-feature-block-content-wrapper">
              <div class="listar-feature-icon-wrapper">
                <div class="listar-feature-icon-inner">
                  <div>
                    <img style={{width:'60%',height:'65%'}} alt="Feedback" class="listar-image-icon" src={PaymentIcon}/>
                  </div>
                </div>
              </div>

              <div class="listar-feature-content-wrapper" style={{paddingTop: '0px'}}>

                <div class="listar-feature-item-title listar-feature-counter-added">
                  <span className='howitworkpoint'><span style={{paddingTop: '20px'}}>03</span>
                  Complete Payment</span>
                </div>

                <div class="listar-feature-item-excerpt">
                Secure payment processing ensures effortless access to premium lead management capabilities. </div>

              </div>
            </div>
          </div>
        </div>
        <div class="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3 listar-feature-item-wrapper listar-feature-with-image listar-height-changed" data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
        <div class="listar-feature-item listar-feature-has-link">

          {/* <a href="https://codepen.io/uiswarup/full/yLzypyY" target="_blank"></a> */}

          <div class="listar-feature-item-inner">

            <div class="listar-feature-right-border"></div>

            <div class="listar-feature-block-content-wrapper">
              <div class="listar-feature-icon-wrapper">
                <div class="listar-feature-icon-inner">
                  <div>
                    <img alt="Customers" class="listar-image-icon" src={LeadMangement}/>
                  </div>
                </div>
              </div>

              <div class="listar-feature-content-wrapper" style={{paddingTop: '0px'}}>

                <div class="listar-feature-item-title listar-feature-counter-added">
                  <span className='howitworkpoint'><span style={{paddingTop: '20px'}}>04</span>Leverage Lead Acceleration </span>
                </div>

                <div class="listar-feature-item-excerpt">
                Unlock powerful lead capture, nurturing, scoring, and routing with Blaccdot's intuitive platform.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
      </div>
    </div>
  </div>
</div>
    </div>
      </div>
    </div>
  );
}

export default Section2;
