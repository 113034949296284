import React from 'react';
import Logo from '../Images/blaccLogo.png';
import { ImLocation2 } from "react-icons/im";
import { IoMdMail } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import { FaSquareInstagram } from "react-icons/fa6";
import {Fade} from 'react-reveal'
import { FaFacebookSquare } from "react-icons/fa";
import {Row,Col} from 'react-bootstrap'
import { MdLeaderboard } from "react-icons/md";
import { RiCustomerServiceFill } from "react-icons/ri";
import { FaPhoneAlt } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import { GiSpikesFull } from "react-icons/gi";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer class="footer-section">
    <div class="container">
        <div class="footer-cta pt-2 pb-0">
        <div className='my-5 container'>
       <Row>
        <Col xs={12} md={4} className=''>
        <div className='d-flex'>
            <div style={{ borderRadius:'50%', border:'2px solid #2E83F4', height:'100px', width:'100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='contactUsCircle'>
            <GiSpikesFull size={50} style={{color:'#ffff'}} />
        </div>
        <div style={{marginTop: '15px'}}>
          <h4 className='ms-3' style={{fontFamily: 'sans-serif',fontWeight: '700',color:'#ffff'}}>100% Lead Capture</h4>
          <p className='ms-3' style={{fontFamily: 'sans-serif',color:'#ffff'}}>No lead left behind</p>
        </div>
        </div>
        </Col>
        <Col xs={12} md={4} className=''>
        <div className='d-flex'>
            <div style={{ borderRadius:'50%', border:'2px solid #2E83F4', height:'90px', width:'100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='contactUsCircle'>
            <MdLeaderboard size={50} style={{color:'#ffff'}} />
        </div>
        <div style={{marginTop: '15px'}}>
          <h4 className='ms-3' style={{fontFamily: 'sans-serif',fontWeight: '700',color:'#ffff'}}>Higher Lead Conversion</h4>
          <p className='ms-3' style={{fontFamily: 'sans-serif',color:'#ffff'}}>Maximum lead ROI</p>
        </div>
        </div>
        </Col>
        <Col xs={12} md={4} className=''>
        <div className='d-flex'>
             <div style={{ borderRadius:'50%', border:'2px solid #2E83F4', height:'100px', width:'130px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='contactUsCircle'>
                <RiCustomerServiceFill size={50} style={{color:'#ffff'}} />
            </div>
            <div style={{marginTop: '15px'}}>
            <h4 className='ms-3' style={{fontFamily: 'sans-serif',fontWeight: '700',color:'#ffff'}}>Accelerated Lead Velocity</h4>
            <p className='ms-3 ' style={{fontFamily: 'sans-serif',marginBottom: '5px',color:'#ffff'}} >
            Lightning-fast lead flow</p>
            </div>
            </div>
        </Col>
       </Row>
    </div>
        </div>
        <div class="footer-content pt-5 pb-5">
            <div class="row" >
                <div class="col-xl-4 col-lg-4 mb-50">
                    <div class="footer-widget">
                        <div class="footer-logo">
                            <a href="index.html"><img src={Logo} class="img-fluid" alt="logo"/></a>
                        </div>
                        <div class="footer-text">
                            <p>
                            Blaccdot Lead Management System is a powerful cloud-based solution that helps businesses 
                            efficiently capture, nurture, and convert leads into customers. Our system streamlines your sales 
                            cycle for maximum productivity WITH 2000+ SUCCESSFUL CLIENTS  </p>
                        </div>
                        <div class="footer-social-icon">
                            <span>Follow us</span>
                            <a target="_blank" href="https://www.instagram.com/_blaccdot_/?next=%2F"><FaSquareInstagram size={25}  /></a>
                            <a target="_blank" href="https://x.com/_blaccdot_"><FaSquareXTwitter size={25}  /></a>
                            <a target="_blank" href="https://www.facebook.com/people/Blacc-dot/61561462727442/"><FaFacebookSquare size={25}  /></a>
                            <a target="_blank" href="https://www.linkedin.com/in/blaccdot/"><FaLinkedin size={25}  /></a>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 mb-30" style={{alignSelf: 'center'}}>
                    <div class="footer-widget">
                        <div class="footer-widget-heading userfull">
                            <h3>Useful Links</h3>
                        </div>
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">about</a></li>
                            <li><a href="#">services</a></li>
                            <li><a href="#">portfolio</a></li>
                            <li><a href="#">Contact</a></li>
                            <li><a href="#">About us</a></li>
                            <li><a href="#">Our Services</a></li>
                            <li><a href="#">Expert Team</a></li>
                            <li><a href="#">Contact us</a></li>
                            <li><a href="#">Latest News</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 mb-50" style={{alignSelf: 'center'}}>
                    {/* <div class="footer-widget">
                        <div class="footer-widget-heading">
                            <h3>Subscribe</h3>
                        </div>
                        <div class="footer-text mb-25">
                            <p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
                        </div>
                        <div class="subscribe-form">
                            <form action="#">
                                <input type="text" placeholder="Email Address"/>
                                <button><IoMdMail size={25} style={{color:'#ffff'}}/></button>
                            </form>
                        </div>
                    </div> */}
                        <li class="nav__item">
                        <div class="footer-widget-heading">
                            <h3>Contact</h3>
                            </div>
                            <div>
                              <p style={{color:'#ffff'}}><IoMdMail size={30} className='me-1' /> support@blaccdot.com</p>
                            </div>
                            <div>
                              <p style={{color:'#ffff'}}><FaPhoneAlt size={25} className='me-1' /> +91 89259 00119</p>
                            </div>
                            <div style={{display:'flex'}}>
                            <MdLocationPin size={30} className='me-1 text-white' /><p style={{color:'#ffff',lineHeight: '27px'}}>10/1028 Seethakathi<br/> 3rd Cross StreetMogappair East,<br/>  Chennai 600037</p>
                            </div>
                    </li>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area" style={{background: '#202020',padding: '18px 0'}}>
        <div class="container">
            <div class="row" >
                <div class="col-xl-6 col-lg-6 text-center text-lg-left">
                    <div className="copyright-text" style={{textAlign: 'justify',alignSelf: "center"}}>
                        <p>Copyright &copy; 2024, All Right Reserved</p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                    <div class="footer-menu" style={{textAlign: 'end'}}>
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">Terms</a></li>
                            <li><a href="#">Privacy</a></li>
                            <li><a href="#">Policy</a></li>
                            <li><a href="#">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
  )
}

export default Footer
