import React from 'react';
import Howwewords from '../Images/Howweword2.png';
import {Fade} from 'react-reveal'
import step1 from '../Images/step-1.png'
import adsaques from '../Images/adsaques.jpg'
import step6 from '../Images/step6.png'
import LeadQualityAssurance from '../Images/LeadQualityAssurance.png'
const Howweword = () => {
  return (
    <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <div className='howworkMain'>
       <Fade top>
        <img src={Howwewords} alt='how we word' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
       </Fade>
      </div>
    
      <div className='howworkReposive'>
      <Fade top>
      <section id="process">
  <div class="row">
    <div class="section-heading">
      <h2 class="text-center orange">How we work?</h2>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="steps-timeline text-center">
        <div class="steps-one">
          <h3>Social Lead Generation</h3>
          <div class="end-circle back-orange"></div>
          <div class="step-wrap">
            <div class="steps-stops">
              <div class="verticle-line back-orange"></div>
            </div>
          </div>
          <div class="pane-warp back-blue">
            <div class="steps-pane">
              <img src={step1}/>
            </div>
          </div>
          <div class="inverted-pane-warp back-blue">
            <div class="inverted-steps-pane">
              <p>Social Lead Generation Leverages social platforms for quality leads.</p>
            </div>
          </div>
        </div>

        <div class="steps-two">
          <h3>Granular Lead Segmentation</h3>
          <div class="step-wrap">
            <div class="steps-stops">
              <div class="verticle-line back-orange"></div>
            </div>
          </div>
          <div class="pane-warp back-orange">
            <div class="steps-pane">
              <img src="https://img.freepik.com/free-vector/sales-funnel-concept-illustration_114360-21139.jpg?t=st=1715157253~exp=1715160853~hmac=007fc6834aa1f3f33080fccc6007edd50c91966829b13a05d9b9737d034097a3&w=740"/>
            </div>
          </div>
          <div class="inverted-pane-warp back-orange">
            <div class="inverted-steps-pane">
              <p>Granular Lead Segmentation Slice and dice leads for personalization. </p>
            </div>
          </div>
        </div>

        <div class="steps-three">
          <h3>Omnichannel Lead<br/> Remarketing</h3>
          <div class="step-wrap">
            <div class="steps-stops">
              <div class="verticle-line back-orange"></div>
            </div>
          </div>
          <div class="pane-warp back-blue">
            <div class="steps-pane">
              <img class="third" src="https://img.freepik.com/free-vector/marketing-promo-clipart-illustrated_52683-74352.jpg?t=st=1715157660~exp=1715161260~hmac=11dfbe14fb810a273eb875b69fee6c5a27320702b64e988159b357c09391c20d&w=740"/>
            </div>
          </div>
          <div class="inverted-pane-warp back-blue">
            <div class="inverted-steps-pane">
              <p>Omnichannel Lead Remarketing Nurture leads across multiple channels seamlessly. </p>
            </div>
          </div>
        </div>

        <div class="steps-four">
          <h3>Lead Ads Acquisition</h3>
          <div class="step-wrap">
            <div class="steps-stops">
              <div class="verticle-line back-orange"></div>
            </div>
          </div>
          <div class="pane-warp back-orange">
            <div class="steps-pane">
              <img src={adsaques}/>
            </div>
          </div>
          <div class="inverted-pane-warp back-orange">
            <div class="inverted-steps-pane">
              <p> Lead Ads Acquisition Capture leads efficiently via paid advertising. </p>
            </div>
          </div>
        </div>
        <div class="steps-four">
          <h3>Tailored Lead Campaigns</h3>
          <div class="step-wrap">
            <div class="steps-stops">
              <div class="verticle-line back-orange"></div>
            </div>
          </div>
          <div class="pane-warp back-orange">
            <div class="steps-pane">
              <img src="https://img.freepik.com/free-vector/hand-drawn-remote-recruitment-illustration_52683-144094.jpg?t=st=1715158016~exp=1715161616~hmac=8c57a6963c50ffeb1233da334bbbdbb7a5c665561377a93a25ffe477046716d7&w=740"/>
            </div>
          </div>
          <div class="inverted-pane-warp back-orange">
            <div class="inverted-steps-pane">
              <p> Tailored Lead Campaigns are Customized campaigns aligned to your offerings. </p>
            </div>
          </div>
        </div>
        <div class="steps-four">
          <h3>Precision Lead Targeting</h3>
          <div class="step-wrap">
            <div class="steps-stops">
              <div class="verticle-line back-orange"></div>
            </div>
          </div>
          <div class="pane-warp back-orange">
            <div class="steps-pane">
              <img src={step6}/>
            </div>
          </div>
          <div class="inverted-pane-warp back-orange">
            <div class="inverted-steps-pane">
              <p> Precision Lead Targeting Target leads with accurate demographic/firmographic filters </p>
            </div>
          </div>
        </div>

        <div class="steps-five">
          <h3>Lead Quality Assurance</h3>
          <div class="inverted-end-circle back-orange"></div>
          <div class="step-wrap">
            <div class="steps-stops">
              <div class="verticle-line back-orange"></div>
            </div>
          </div>
          <div class="pane-warp back-blue">
            <div class="steps-pane">
              <img src={LeadQualityAssurance} style={{height:'130px'}}/>
            </div>
          </div>
          <div class="inverted-pane-warp back-blue">
            <div class="inverted-steps-pane">
              <p>Lead Quality Assurance Stringent verification ensures the highest-quality lead data.</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
      </Fade>
      </div>
    </div>
  );
}

export default Howweword;
