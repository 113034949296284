import React from 'react'
import {Row,Col} from 'react-bootstrap';
import a4logo from '../Images/a4logo.png';
import Sulekha from '../Images/Sulekha.png';
import sumukha from '../Images/sumukha.png';
import madeen from '../Images/madeen.png';
import Truefriend from '../Images/Truefriend.png'
import natural from '../Images/natural.png'
import ismo from '../Images/ismo.png'
import Amided from '../Images/Amided.png'
import sk from '../Images/sk.png'
import acvl from '../Images/acvl.png'
import adhiam from '../Images/adhiam.png'
import rentla from '../Images/rentla.png'

const Ourclient = () => {
  return (
    <div>
      <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
      <div class="site-heading text-center">
           <h2>Our <span>Client</span></h2>
           <h4>2,000+ businesses by Blaccdot's lead acceleration worldwide.</h4>
       </div>
      </div>

      <div className='container'>
        <Row className='ourClintlogo'>
          <Col xs={6} md={2} className='ourClintlogo' >
          <div style={{borderRadius:'10px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
            <img src={a4logo} alt='a4logo' style={{ width: '80%' }} />
          </div>
          </Col>
          <Col xs={6} md={2} className='ourClintlogo'>
            <div style={{borderRadius:'10px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                <img src={Sulekha} alt='a4logo' style={{ width: '70%' }} />
            </div>
          </Col>
          <Col xs={6} md={2} className='ourClintlogo'>
            <div style={{borderRadius:'10px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                <img src={sumukha} alt='a4logo' style={{ width: '50%' }} />
            </div>
          </Col>
          <Col xs={6} md={2} className='ourClintlogo'>
            <div style={{borderRadius:'10px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                <img src={madeen} alt='a4logo' style={{ width: '70%' }} />
            </div>
          </Col>
          <Col xs={6} md={2} className='ourClintlogo'>
            <div style={{borderRadius:'10px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                <img src={Truefriend} alt='a4logo' style={{ width: '80%' }} />
            </div>
          </Col>
          <Col xs={6} md={2} className='ourClintlogo'>
            <div style={{borderRadius:'10px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                <img src={natural} alt='a4logo' style={{ width: '70%' }} />
            </div>
          </Col>
          <Col xs={6} md={2} className='ourClintlogo'>
          <div style={{borderRadius:'10px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
            <img src={ismo} alt='a4logo' style={{ width: '60%' }} />
          </div>
          </Col>
          <Col xs={6} md={2} className='ourClintlogo'>
            <div style={{borderRadius:'10px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                <img src={sk} alt='a4logo' style={{ width: '50%' }} />
            </div>
          </Col>
          <Col xs={6} md={2} className='ourClintlogo'>
            <div style={{borderRadius:'10px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                <img src={Amided} alt='a4logo' style={{ width: '35%' }} />
            </div>
          </Col>
          <Col xs={6} md={2} className='ourClintlogo'>
            <div style={{borderRadius:'10px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                <img src={acvl} alt='a4logo' style={{ width: '70%' }} />
            </div>
          </Col>
          <Col xs={6} md={2} className='ourClintlogo'>
            <div style={{borderRadius:'10px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                <img src={adhiam} alt='a4logo' style={{ width: '70%' }} />
            </div>
          </Col>
          <Col xs={6} md={2} className='ourClintlogo'>
            <div style={{borderRadius:'10px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                <img src={rentla} alt='a4logo' style={{ width: '70%' }} />
            </div>
          </Col>
        </Row>
      </div>


      {/* <div className='container'>
      <div>
      <div class="sliderL">
	<div class="slide-track">
		<div class="slide2">
			<img src={a4logo} height="100" width="250" alt="" />
		</div>
		<div class="slide2">
			<img src={a4logo} height="100" width="200" alt="" />
		</div>
		<div class="slide2">
			<img src={a4logo} height="100" width="250" alt="" />
		</div>
		<div class="slide2">
			<img src={a4logo} height="100" width="200" alt="" />
		</div>
		<div class="slide2">
			<img src={a4logo} height="100" width="250" alt="" />
		</div>
		<div class="slide2">
			<img src={a4logo} height="100" width="200" alt="" />
		</div>
		<div class="slide2">
			<img src={a4logo} height="100" width="250" alt="" />
		</div>
		<div class="slide2">
			<img src={a4logo} height="100" width="200" alt="" />
		</div>
		<div class="slide2">
			<img src={a4logo} height="100" width="250" alt="" />
		</div>
		<div class="slide2">
			<img src={a4logo} height="100" width="200" alt="" />
		</div>
		<div class="slide2">
			<img src={a4logo} height="100" width="250" alt="" />
		</div>
		<div class="slide2">
			<img src={a4logo} height="100" width="200" alt="" />
		</div>
		<div class="slide2">
			<img src={a4logo} height="100" width="250" alt="" />
		</div>
		<div class="slide2">
			<img src={a4logo} height="100" width="200" alt="" />
		</div>
	</div>
</div>
    </div>
       </div> */}
    </div>
  )
}

export default Ourclient
