import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import Home from './Screen/Home'
import Selectserives from './Screen/Selectserives'
import Test from './Screen/Test';
import Selectsubserives from './Screen/Selectsubserives'
import Finalform from './Screen/Finalform'
import SelectrealEaste from './Screen/SelectrealEaste'
import FinancialService from './Screen/FinancialService'
import Manufacturing from './Screen/Manufacturing'
import Automotive from './Screen/Automotive'
import Ecommers from './Screen/Ecommers'
import Appointment from '../src/Screen/Appointment'
import Thankyou from './Screen/Thankyou'


function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/selectservices' element={<Selectserives/>}/>
          <Route path='/selectsubserives' element={<Selectsubserives/>}/>
          <Route path='/selectrealEaste' element={<SelectrealEaste/>}/>
          <Route path='/financialService' element={<FinancialService/>}/>
          <Route path='/manufacturing' element={<Manufacturing/>}/>
          <Route path='/automotive' element={<Automotive/>}/>
          <Route path='/ecommers' element={<Ecommers/>}/>
          <Route path='/finalform' element={<Finalform/>}/>
          <Route path='/appointment' element={<Appointment/>}/>
          <Route path='/thankyou' element={<Thankyou/>}/>
          <Route path='/Test' element={<Test/>}/>
          </Routes>
       </Router>

    </div>
  );
}

export default App;
