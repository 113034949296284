import {Row,Col} from 'react-bootstrap';
import React, { useState } from 'react';
import {Fade} from 'react-reveal'
import Accordion from 'react-bootstrap/Accordion';
import faq from '../Images/faq.png'



const Faq = () => {
  return (
    <div className='mt-5'>
      <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
      <div class="site-heading text-center">
           <h2>Frequently Asked <span>Questions</span></h2>
           <h4 style={{fontSize:'20px'}}>Loved by 2000+ customers all over the world</h4>
       </div>
      </div>
      <div className='container mb-5'>
      <Row>
        
        <Col xs={12} md={6}>
        <Fade left>
        <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is Blaccdot lead management system?</Accordion.Header>
        <Accordion.Body>
        Blaccdot provides an all-in-one platform to capture, nurture, score, and 
        convert leads into customers. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>How does Blaccdot's lead scoring work? </Accordion.Header>
        <Accordion.Body>
        Blaccdot uses AI-powered lead-scoring models to prioritize hot leads based 
        on fit and engagement. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>What lead nurturing capabilities does Blaccdot offer?</Accordion.Header>
        <Accordion.Body>
        Blaccdot enables automated multi-touch nurture campaigns tailored to each 
       lead's interests and lifecycle stage. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>How can I capture more leads with Blaccdot? </Accordion.Header>
        <Accordion.Body>
        Blaccdot integrates with web forms, landing pages, live chat, call tracking, and 
        ad platforms.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header> What is Blaccdot's lead management process?</Accordion.Header>
        <Accordion.Body>
        Blaccdot's process guides lead through acquisition, qualification, nurturing, 
        routing, and conversion seamlessly.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header> Which integrations does Blaccdot support? </Accordion.Header>
        <Accordion.Body>
        Blaccdot integrates natively with popular CRMs, marketing automation, ad 
platforms, and sales tools. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>How does Blaccdot improve lead conversion rates? </Accordion.Header>
        <Accordion.Body>
        With AI lead scoring, behavior tracking, automated nurturing, and sales 
      enablement content. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header>What is Blaccdot's omnichannel lead nurturing?</Accordion.Header>
        <Accordion.Body>
        Blaccdot engages leads across email, website, social, phone, and in-person 
        channels automatically.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </Fade>
        </Col>

        <Col xs={12} md={6} className='text-center mb-5 faqImage'>
        <Fade right>
          <img style={{width:'80%'}} src={faq} alt='faq'/>
          </Fade>
        </Col>
        
      </Row>
      </div>
    </div>
  )
}

export default Faq
