import React,{useState} from 'react';
import {Row,Col,Button} from 'react-bootstrap'
import {Fade} from 'react-reveal'
import PartnerCircle from '../Images/PartnerCircle.png'

const EuctoPartner = () => {


    const [isOpen, setIsOpen] = useState(false);

    const openVideo = () => {
      setIsOpen(true);
    };
  
    const closeVideo = () => {
      setIsOpen(false);
    };



  return (
    <div style={{ backgroundColor:'#E8EEEE', width: '100%' }}>
      <div className='container' style={{paddingTop:'90px',paddingBottom:'50px'}}>
        <Row>
          <Col xs={12} md={6}>
          <Fade left>
            <span style={{color:'#C99300',fontfamily: 'sans-serif'}}>OUR MARKETING  PARTNER</span>
            <h2 style={{fontWeight: '700',fontfamily: 'sans-serif',fontSize: '45px'}}>Blaccdot is Verified!</h2>
            <h5 style={{fontWeight: '600',fontfamily: 'sans-serif',fontSize: '35px'}}>Trusted by Revenue Leaders </h5>
           <p className='mt-3 mb-4 myMarketing' style={{width: '80%'}}>Blaccdot fuels growth for leading brands worldwide with intelligent lead 
            management. Our unified platform seamlessly captures, nurtures, scores, 
            and routes the hottest leads.
                        </p>

            <div style={{ display: 'flex', alignItems: 'center' }}>
      <a href='/selectservices'><button className='me-2 button-41 mb-5'>Get a Quote</button></a>
      {/* <>
      <a id="play-video" className="video-play-button" href="#" onClick={openVideo}>
        <span></span>
      </a>

      {isOpen && (
        <div id="video-overlay" className="video-overlay open">
          <a className="video-overlay-close" onClick={closeVideo}>&times;</a>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/Vjc2S34ZjOg?si=hGM0VrPegEKoJnKm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      )}
    </> */}
    </div>
    </Fade>
          </Col>
           <Col xs={12} md={6}>
           <Fade right>
             <img style={{width:'100%'}} src={PartnerCircle} alt='Eucto-partner' />
             </Fade>
           </Col>
        </Row>
      </div>
    </div>
  );
}

export default EuctoPartner;
