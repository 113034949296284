import React, { useState } from 'react';
import Headers from '../components/Header';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';
import Hero from '../Images/hero.jpg';
import { Zoom, Fade } from 'react-reveal';
import TextField from '@mui/material/TextField';
import { IoChevronBackCircleOutline } from 'react-icons/io5';


const Finalform = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');

  const handleSubmit = () => {
    if (validateForm()) {
      sessionStorage.setItem('name', name);
      sessionStorage.setItem('email', email);
      sessionStorage.setItem('number', number);
      // Other sessionStorage items...
      navigate('/appointment');
    }
  };

  const validateForm = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const contactRegex = /^\d+$/;

    if (!name.trim()) {
      errors.fullName = 'Full Name is required';
    }

    if (!email.trim()) {
      errors.email = 'Email is required';
    } else if (!emailRegex.test(email)) {
      errors.email = 'Invalid email format';
    }

    if (!number.trim()) {
      errors.contactNo = 'Contact Number is required';
    } else if (!contactRegex.test(number)) {
      errors.contactNo = 'Contact Number must contain only numbers';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newErrors = { ...errors };
    if (name === 'fullName') {
      setName(value);
      delete newErrors['fullName'];
    } else if (name === 'email') {
      setEmail(value);
      delete newErrors['email'];
    } else if (name === 'contactNo') {
      setNumber(value);
      delete newErrors['contactNo'];
    }
    setErrors(newErrors);
  };

  return (
    <div>
      <Headers />
      <div className="container-fluid py-3" style={{ backgroundColor: '#E9F0F6' }}>
        <div className="formBoxsh">
          <div className="container">
            <Row>
              {/* Rest of your code */}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finalform;
