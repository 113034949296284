import EuctoLogo from '../Images/blaccLogo.png'
import  '../components/component.css';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Callheader from '../Images/Callheader.png'

function Header() {
  return (
    <Navbar expand="lg" style={{backgroundColor:'#eeee'}}>
      <Container>
        <Navbar.Brand href="/">
          <img src={EuctoLogo} alt='eucto' style={{width: '100px'}} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav style={{alignItems: "center", margin: '0 16%'}}>
            <Nav.Link href="#action1">HOME</Nav.Link>
            <Nav.Link href="#action1">SERVICE</Nav.Link>
            <Nav.Link href="#action1">ABOUTUS</Nav.Link>
            <Nav.Link href="#action1">SUPPORT</Nav.Link>
          </Nav>
          <Form className="d-flex" style={{width:'100%',alignItems: 'center'}}>           
          <button class="button-47">Sign up</button>
          <div style={{borderLeft: '1px solid green',height: '20px', marginLeft:'10px'}} ></div>
          <img src={Callheader} alt='imageCallheader' style={{width:'15%',marginLeft:'10px'}} />
          <div className='d-grid'>
            <span style={{fontWeight: '100'}}>Have any Questions?</span>
            <span>support@blaccdot.com</span>
          </div>
        </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;