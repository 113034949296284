import React from 'react'
import { MdHealthAndSafety } from "react-icons/md";
import { MdRealEstateAgent } from "react-icons/md";
import { SiMoneygram } from "react-icons/si";
import { MdPrecisionManufacturing } from "react-icons/md";
import {FaCarCrash} from "react-icons/fa";
import { HiShoppingBag } from "react-icons/hi2";
import {Fade} from 'react-reveal'

const Ourservice = () => {

  return (
    <div>
      <section class="we-offer-area text-center bg-gray">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="site-heading text-center">
                        <h2>Our <span>Service</span></h2>
                        <h4>Blaccdot Delivers Results, Simplified in your space</h4>
                    </div>
                </div>
            </div>
           
                <div class="row our-offer-items less-carousel">
                    
                    <div class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                        <i className="fas fa-pen-fancy">
                        <MdHealthAndSafety size={50} style={{marginBottom:'10px'}}/>
                        </i>
                            <h4>Health Care</h4>
                            <p>
                            Blaccdot's streamlines qualified healthcare leads for providers and manufacturers.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                              <i className="fas fa-pen-fancy">
                                <MdRealEstateAgent size={50} style={{marginBottom:'10px'}}/>
                               
                                </i>
                            <h4>Real Estate</h4>
                            <p>
                            Capture and nurture real estate leads with Blaccdot's automation. 
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                            <i className="fas fa-pen-fancy">
                              <SiMoneygram size={50} style={{marginBottom:'10px'}}/>
                            </i>
                            <h4>Financial Service</h4>
                            <p>
                             Blaccdot's AI qualifies and routes financial services lead efficiently. 
                            </p>
                        </div>
                    </div>
               
                    <div class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                          <i className="fas fa-pen-fancy">
                           <MdPrecisionManufacturing size={50} style={{marginBottom:'10px'}}/>
                         </i>
                            <h4>Manufacturing</h4>
                            <p>
                            Blaccdot's consolidates manufacturing leads from multiple sources into one. 
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                            <i className="fas fa-pen-fancy">
                              <FaCarCrash size={50} style={{marginBottom:'10px'}}/>
                            </i>
                            <h4>Automotive</h4>
                            <p>Accelerate the automotive lead-to-sale cycle with Blaccdot's solution.      </p>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                        <i className="fas fa-pen-fancy">
                          <HiShoppingBag size={50} style={{marginBottom:'10px'}}/>
                        </i>
                            <h4>E-Commerce</h4>
                            <p>
                            Maximize e-commerce revenue with Blaccdot's lead capture and nurturing.                             </p>
                        </div>
                    </div>
                </div>
        </div>
    </section>
    </div>
  )
}

export default Ourservice
